import axios from 'axios';

export const resetPassword = (token, password, callback) => async () => {
  const config = {
    url: `/update_password/${token}`,
    method: 'POST',
    data: {user: {password}}
  };
  const result = await axios.request(config);
  callback(result.data);
};

export const verifyToken = (token, callback) => async () => {
  const config = {
    url: `/update_password/${token}`,
    method: 'GET'
  };
  const result = await axios.request(config);
  callback(result.data);
};
