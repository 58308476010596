import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {LocaleProvider} from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn/SignIn";
import TokenSignUp from "../TokenSignUp/TokenSignUp";
import * as actions from './LayoutActions';
import {bindActionCreators} from "redux";
import 'intl';
import ResetPassword from "../ResetPassword/ResetPassword";
import ResetEmailSent from "../ResetPassword/ResetEmailSent";
import NewPasswordForm from "../ResetPassword/NewPasswordForm";
import NewPasswordSuccess from "../ResetPassword/NewPasswordSuccess";
import InvoicePdf from "../Pdf/InvoicePdf";

class App extends Component {


  componentDidMount() {
    this.props.actions.setupAxios();
  }

  render() {
    const {configured} = this.props;
    return configured ? this.renderLayout() : null;
  }

  renderLayout() {
    const {match, location, locale, authenticated} = this.props;

    if (location.pathname === '/') {
      if (!authenticated) {
        return (<Redirect to={'/signin'}/>);
      }
      return (<Redirect to={'/invoices'}/>);
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Switch>
            <Route exact path='/reset_password' component={ResetPassword}/>
            <Route exact path='/reset_email_sent/:email' component={ResetEmailSent}/>
            <Route exact path='/new_password/:token' component={NewPasswordForm}/>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/password_reset' component={NewPasswordSuccess}/>
            <Route path='/token_signup/:token' component={TokenSignUp}/>
            <Route path={`${match.url}invoice_pdf/:token`} component={InvoicePdf}/>
            <Route path={`${match.url}`} component={MainApp}/>
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

const mapStateToProps = ({persistedState, transientState, settings}) => ({
  authenticated: persistedState.authenticated,
  configured: transientState.configured,
  locale: settings.locale
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
