import {Button, Card, Form, Input,} from 'antd';
import React, {Component} from 'react';
import * as actions from "./ResetPasswordActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {formItemLayout} from "../Common/FormDefaults";


class ResetPassword extends Component {

  save = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.props.actions.resetPassword(values.email,
        () => this.props.history.push(`/reset_email_sent/${values.email}`),
        error => this.setState({error}));
    });
  };

  render = () => {
    const {getFieldDecorator} = this.props.form;
    return <div className="gx-main-content-wrapper">
      <Card className="gx-card" title="Reset password">
        <Form onSubmit={this.save} {...formItemLayout}>
          <Form.Item
            label="Email address"
          >
            {getFieldDecorator('email', {
              rules: [{required: true, message: 'Please input your email address!'}]
            })(
              <Input/>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Reset
            </Button>
            <Button type="ghost" onClick={this.props.cancel}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>;
  };

}

ResetPassword.propTypes = {};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(Form.create()(ResetPassword)));
