import React, {Component} from "react";
import {Avatar, Popover} from "antd";
import person from '../../assets/images/placeholder.jpg'
import * as actions from "./ProfileDropdownActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";

class ProfileDropdown extends Component {

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li onClick={() => {
          this.props.actions.logout();
        }}>Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={person} className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(ProfileDropdown));
