import axios from 'axios';

export const resetPassword = (email, callback) => async () => {
  const config = {
    url: '/reset_password',
    method: 'POST',
    data: {email}
  };
  const result = await axios.request(config);
  callback(result.data);
};
