import React, {Component} from 'react';
import * as actions from "./InvoicePdfActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {defaultDateFormat} from "../Common/FormDefaults";
import moment from "moment";
import {getNumbers} from "../Invoices/InvoiceItems/InvoiceTools";
import numeral from "numeral";
import CustomFieldsValues from "../Common/CustomFields/CustomFieldsValues";
import InvoicePdfModal from "./InvoicePdfModal"
import {Card, Table, Button, Col, Row, Switch, Input} from 'antd';
import DataBox from "../../components/Data/DataBox";

class InvoicePdf extends Component {
  initialState = {
    invoice: {
      line_items: [],
      payments: []
    },
    loading: true,
    showModal: null,
    columns: [
      {
        title: () => (this.state.invoice.submit_date ? ' ' : <Switch onChange={(e) => this.onSelectAll(e)} checked={this.state.checkedAll}/>),
        dataIndex: 'item.accepted',
        render: (e, record) => (<Switch onChange={(e) => this.onSelect(e, record)} checked={record.accepted} disabled={this.state.invoice.submit_date} />),
        width: 100

      },
      {
        title: 'Name',
        dataIndex: 'item.name',
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Unit',
        dataIndex: 'unit'
      },
      {
        title: 'Price',
        dataIndex: 'price'
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity'
      },
      {
        title: 'Total',
        dataIndex: 'total',
      }
    ]
  };

  state = {...this.initialState};

  componentDidMount() {
    this.setState({token: this.props.match.params.token}, this.reload);
  }

  goToIndex = () => {
    const {token} = this.state;
    this.props.history.push(`/invoice_pdf/${token}`);
  };

  viewLoaded = () => {
    setTimeout(() => {
      window.status = 'rendered';
    }, 1000);
  };

  reload = () => {
    const {token} = this.state;
    this.props.actions.loadInvoiceByToken(token, invoice => {
      this.setState({invoice, loading: false}, this.viewLoaded);
    });
  };

  onSelect = (e, record) => {
    const {invoice} = this.state;
    this.setState({checked: e});
    e ? record.accepted = true : record.accepted = false;
    const disapproved = invoice.line_items.find((item) => !item.accepted);
    if(disapproved) {
      this.setState({checkedAll: false})
    }
    else {
      this.setState({checkedAll: true})
    }
  };

  onSelectAll = (e) => {
    this.setState({checkedAll: e});
    const {invoice} = this.state;
    invoice.line_items.map((item) => {
      e ? item.accepted = true : item.accepted = false
    })
  };

  onChangeReason = (e, record) => {
  record.reason = e.target.value;
  };

  render() {

    const {loading, token, invoice, showModal} = this.state;
    const { TextArea } = Input;
    if (loading) {
      return 'Loading ...';
    }
    const customer = invoice.customer;
    const numbers = getNumbers(invoice);
    invoice.total = numbers.total;
    invoice.amount_paid = numbers.paid;
    invoice.amount_due = numbers.due;
    return (
      <>
        <section className="gx-app-layout ant-layout ant-layout-has-sider">
          <div className="gx-layout-content ant-layout-content">
            <div className="gx-main-content-wrapper">
              <Row id="top-cards">
                <Col xl={24} lg={12} md={12} sm={24} xs={24}>
                  <Card title="Invoice Information" className="gx-card">
                    {invoice.submit_date &&
                    <h2 className="gx-pdf-notification"><i className="icon icon-exclamation gx-mr-2"/>You have already
                      submitted
                      this Invoice!</h2>
                    }
                    <Row>
                      <Col xs={24}>
                        <p className="ant-card-head-title">Issuer:</p>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox value={invoice.client.name}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <p className="ant-card-head-title">Customer:</p>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Name" value={customer.name}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Company name" value={customer.company_name}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Phone" value={customer.phone}/>
                      </Col>
                      <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Email" value={customer.email}/>
                      </Col>
                      <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Address" value={customer.formatted_address}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <p className="ant-card-head-title">Invoice:</p>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Total" value={numeral(invoice.total).format('$0,0.00')}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Amount Paid" value={numeral(invoice.amount_paid).format('$0,0.00')}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Amount Due" value={numeral(invoice.amount_due).format('$0,0.00')}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Discount" value={numeral(invoice.discount).format('$0,0.00')}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Issue Date" value={moment(invoice.issue_date).format(defaultDateFormat)}/>
                      </Col>
                      <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                        <DataBox title="Due Date" value={moment(invoice.due_date).format(defaultDateFormat)}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <p className="ant-card-head-title">Custom Fields:</p>
                      </Col>
                      <CustomFieldsValues id={invoice.id} referencedModelName="Invoice" referencedView="template_pdf"/>
                    </Row>
                  </Card>
                </Col>
                <Col xl={24} lg={12} md={12} sm={24} xs={24}>
                  <Card title="Items" className="gx-card" actions={
                    [
                      !invoice.submit_date &&
                      <Button className="gx-mb-0" onClick={() => {
                        this.setState({showModal: 'new'})
                      }}> Submit
                      </Button>
                    ]
                  }>
                    <Table expandIconAsCell={false}
                           expandIconColumnIndex={-1}
                           defaultExpandAllRows
                           expandedRowRender={record => !record.accepted && <p>Disapproval Reason:<TextArea disabled={invoice.submit_date} defaultValue={record.reason} onChange={(e) => this.onChangeReason(e, record)} rows={4} /></p>}
                           columns={this.state.columns}
                           dataSource={invoice.line_items}
                           pagination={false}/>
                  </Card>
                </Col>

              </Row>
              {showModal &&
              <InvoicePdfModal
                invoice={invoice}
                token={token}
                id={showModal !== 'new' ? showModal : null}
                cancel={() => this.setState({showModal: null}, this.reload)}/>}
            </div>
          </div>
        </section>
      </>
    );
  }
}


const mapStateToProps = ({persistedState}) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicePdf));
