import React, {Component} from "react";
import {connect} from "react-redux";
import {Drawer, Layout} from "antd";

import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav, updateWindowWidth} from "./SidebarActions";

const {Sider} = Layout;

export class Sidebar extends Component {

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const {navCollapsed} = this.props;
    return (
      <Sider
        className="gx-app-sidebar gx-collapsed-sidebar"
        trigger={null}
        collapsed={false}
        theme="lite"
        collapsible>
        <Drawer
          wrapClassName="gx-drawer-sidebar"
          placement="left"
          closable={false}
          onClose={this.onToggleCollapsedNav}
          visible={navCollapsed}>
          <SidebarContent/>
        </Drawer> :
        <SidebarContent/>
      </Sider>)
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed} = settings;
  return {navCollapsed}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, updateWindowWidth})(Sidebar);
