import axios from 'axios';
import {CONFIGURED} from "../../appRedux/reducers/TransientStateReducer";
import {LOGOUT} from "../../appRedux/reducers/PersistedStateReducer";
import message from 'vanilla-antd-message'
import {getBaseUrl} from "../../config/applicationConfiguration";

export const setupAxios = () => (dispatch, getState) => {
  axios.defaults.baseURL = getBaseUrl();
  axios.interceptors.request.use(config => {
    const state = getState().persistedState;
    const token = state.token;
    if (token != null && !config.anonymous) {
      config.headers['access-token'] = token;
      config.headers['client'] = state.client;
      config.headers['uid'] = state.uid;
    }
    return config;
  }, err => {
    //TODO: handle error
    message.error('Request error');
    return Promise.reject(err);
  });
  axios.interceptors.response.use((response) => {
    //TODO: we can update access token here
    if (response.config.message) {
      message.success(response.config.message);
    }
    return response;
  }, (error) => {
    let response = error.response;
    if (response && (response.status === 403 || response.status === 401)) {
      dispatch({type: LOGOUT});
    } else if (response && (response.config && !response.config.skipAlert)) {
      //TODO: handle error
      message.error('Server error');
    }
    return Promise.reject(error);
  });
  dispatch({type: CONFIGURED});
};
