import React, {Component} from "react";
import {Layout} from "antd";

import Sidebar from "../Sidebar/Sidebar";

import Topbar from "../Topbar/Topbar";
import {footerText} from "util/config";
import App from "../../routes/index";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const {Content, Footer} = Layout;

export class MainApp extends Component {

  componentDidMount() {
    this.logout(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.logout(nextProps);
  }

  logout(props) {
    if (!props.authenticated) {
      this.props.history.push(`/signin`);
    }
  }

  render() {
    const {match} = this.props;

    return (
      <Layout className="gx-app-layout">
        <Sidebar/>
        <Layout>
          <Topbar/>
          <Content className="gx-layout-content">
            <App match={match}/>
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
}


const mapStateToProps = ({persistedState, transientState, settings}) => ({
  authenticated: persistedState.authenticated
});


export default withRouter(connect(mapStateToProps)(MainApp));
