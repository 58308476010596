import {AutoComplete, Form} from 'antd';
import React, {Component} from 'react';
import PropTypes from "prop-types";


class CustomAutocomplete extends Component {

  state = {refresh: false};

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.defaultValue && nextProps.defaultValue
      || (this.props.defaultValue && nextProps.defaultValue && this.props.defaultValue.id !== nextProps.defaultValue.id)) {
      this.setState({refresh: true});
    }
  }

  render() {
    const {refresh} = this.state;
    const {searchResults, defaultValue, setRef, onSelect, onSearch, label, formatter} = this.props;
    const options = searchResults;
    if (label && !options.some(x => x.id === 'new')) {
      options.push({id: 'new', name: `Add new ${label}`})
    }
    if (defaultValue && defaultValue.id && !options.some(x => x.id === defaultValue.id)) {
      options.push(defaultValue);
    }
    if (refresh) {
      this.setState({refresh: false});
      return null;
    }

    const defaultProps = defaultValue ? {defaultValue: defaultValue.name} : {};
    return (
      <>
        <AutoComplete
          ref={setRef}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder="Search..."
          {...defaultProps}
        >
          {formatter ? formatter(options) : options.map(c => <AutoComplete.Option key={c.id}
                                                                                  value={c.id.toString()}>{c.name}</AutoComplete.Option>)}
        </AutoComplete>
      </>)
  }
}

CustomAutocomplete.propTypes = {
  searchResults: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
  setRef: PropTypes.func,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  formatter: PropTypes.func
};

export default Form.create()(CustomAutocomplete);
