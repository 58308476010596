import React, {Component} from "react";
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo_xs.png'

class SidebarLogo extends Component {

  render() {
    return (
      <div className="gx-layout-sider-header">
        <Link to="/" className="gx-site-logo">
          <img alt="" src={logo}/>
        </Link>
      </div>
    );
  }
}

export default SidebarLogo;
