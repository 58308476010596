import {Col} from 'antd';
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "./CustomFormFieldsActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DataBox from "../../../components/Data/DataBox";


class CustomFieldsValues extends Component {

  state = {customFields: []};

  componentDidMount() {
    const {id, referencedModelName, client_id} = this.props;
    this.props.actions.loadCustomFields(id, client_id, referencedModelName, customFields => this.setState({customFields}));
  }

  render() {
    const {customFields} = this.state;
    const {referencedView} = this.props;

    if (referencedView === 'template_pdf') {
      return <>{customFields.map((field, index) => (
          <Col xl={2} lg={12} md={12} sm={24} xs={24}>
            <DataBox title={field.name} value={field.value || '-'}/>
          </Col>
        ))}</>;
    } else {
      return <>{customFields.map((field, index) => (
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DataBox title={field.name} value={field.value || '-'}/>
        </Col>
      ))}</>;
    }
  }
}


CustomFieldsValues.defaultProps = {};

CustomFieldsValues.propTypes = {
  client_id: PropTypes.number,
  form: PropTypes.object.isRequired,
  referencedModelName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(CustomFieldsValues));
