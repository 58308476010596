import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link, withRouter} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import {toggleCollapsedSideNav} from "./SidebarActions";

class SidebarContent extends Component {

  render() {
    const {pathname} = this.props.location;
    const {role} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (<Auxiliary>

        <SidebarLogo/>
        {role === 'superadmin' &&
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar mt-2">
            <Menu
              onClick={()=> this.props.toggleCollapsedSideNav(false)}
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme="lite"
              mode="inline">
              <Menu.Item key="clients">
                <Link to="/superadmin"><i className="icon icon-company"/>
                  <span>Clients</span>
                </Link>
              </Menu.Item>
            </Menu>
          </CustomScrollbars>
        </div>
        }
      {role != 'superadmin' &&
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar mt-2">
            <Menu
              onClick={()=> this.props.toggleCollapsedSideNav(false)}
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme="lite"
              mode="inline">
              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-chart"/>
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="quotes">
                <Link to="/quotes"><i className="icon icon-chat-bubble"/>
                  <span>Estimates</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="invoices">
                <Link to="/invoices"><i className="icon icon-copy"/>
                  <span>Invoices</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="customers">
                <Link to="/customers"><i className="icon icon-user"/>
                  <span>Clients</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="items">
                <Link to="/items"><i className="icon icon-card"/>
                  <span>Items</span>
                </Link>
              </Menu.Item>
              {role === 'admin' &&
              <Menu.Item key="settings">
                <Link to="/settings"><i className="icon icon-setting"/>
                  <span>Settings</span>
                </Link>
              </Menu.Item>
              }
            </Menu>
          </CustomScrollbars>
        </div> }
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, persistedState}) => {
  const {locale, pathname} = settings;
  const {role} = persistedState;
  return {locale, pathname, role}
};
export default withRouter(connect(mapStateToProps,{toggleCollapsedSideNav})(SidebarContent));
