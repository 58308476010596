import React from "react";

const DataBox = ({title, value, className, layout = false}) => {

  return (
    <div className={`pn-data-box ${className} ${layout ? 'pn-data-box-' + layout : ''}`}>
      <div className="pn-data-box-title">
        {title}
      </div>
      <div className="pn-data-box-value">
        {value}
      </div>
    </div>
  )
};

export default DataBox;
