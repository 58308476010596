import React, {Component} from "react";
import {Provider} from "react-redux";
import {Route, Router, Switch} from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore from "./appRedux/reducers/store";
import history from './appRedux/reducers/history';
import App from "./containers/App/index";
import {PersistGate} from 'redux-persist/es/integration/react';

export const {persistor, store} = configureStore();

class NextApp extends Component {


  render() {
    return (
      <Provider store={store}>
        <PersistGate
          loading={'Loading...'}
          persistor={persistor}>
          <Router history={history}>
            <Switch>
              <Route path="/" component={App}/>
            </Switch>
          </Router>
        </PersistGate>
      </Provider>);
  }
}


export default NextApp;
