import {Card,} from 'antd';
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";

class NewPasswordSuccess extends Component {

  render = () => {
    return <div className="gx-main-content-wrapper">
      <Card className="gx-card" title="Reset password">
        Your password has been reset, try to login with your new password here: <Link to={'/'}>Here</Link>
      </Card>
    </div>;
  };
}

NewPasswordSuccess.propTypes = {};

export default withRouter(NewPasswordSuccess);
