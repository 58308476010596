import React, {Component} from "react";
import {Layout, Popover} from "antd";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav} from "./TopbarActions";
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import ProfileDropdown from "./ProfileDropdown";

const {Header} = Layout;

class Topbar extends Component {

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  render() {
    const {locale, navCollapsed} = this.props;
    return (
      <Auxiliary>
        <Header>
          <div className="gx-linebar gx-mr-3">
            <i className="gx-icon-btn icon icon-menu"
               onClick={() => {
                 this.props.toggleCollapsedSideNav(!navCollapsed);
               }}
            />
          </div>
          <ul className="gx-header-notifications gx-ml-auto">
            <Auxiliary>
              <li className="gx-user-nav"><ProfileDropdown/></li>
            </Auxiliary>
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale, navCollapsed} = settings;
  return {locale, navCollapsed}
};

export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage})(Topbar);
