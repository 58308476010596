import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import * as actions from "./SignInPageActions";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import {bindActionCreators} from "redux";

const FormItem = Form.Item;

class SignIn extends React.Component {
  state = {
    validation: null,
    loader: false
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({validation: null});

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loader: true});
        this.props.actions.signIn(values, (user) => {
          this.setState({loader: false});
          if(user.role === 'superadmin'){
            this.props.history.push('/superadmin')
          } else {
            this.props.history.push('/invoices')
          }
        }, e => {
          this.setState({loader: false});
          this.setState({validation: e})
        });
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {loader, validation} = this.state;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.onSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "admin@petstablished.com",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "test123",
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox>Remember me</Checkbox>
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link>
                </FormItem>
                <span
                  className="gx-text-light gx-fs-sm">Don't remember your passowrd? <Link
                  to="/reset_password">Reset here</Link></span>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {validation || null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(WrappedNormalLoginForm));
