import React from "react";
import CustomAutocomplete from "./CustomAutocomplete";

export const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    md: {span: 24},
    lg: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
    md: {span: 24},
    lg: {span: 16},
  },
};

export const joditEditorStyle = {
  height: 400,
  width:800
};

export const joditEditorReadOnly = {
  height: 300,
  readonly: true,
  toolbar: false
};

export const defaultDateFormat = 'MM/DD/YYYY';
export const serverDateFormat = 'YYYY-MM-DD';


export const renderAutocomplete = (searchResults, defaultValue, setRef, onSelect, onSearch, label, formatter) => {
  return <CustomAutocomplete
    defaultValue={defaultValue}
    searchResults={searchResults}
    setRef={setRef}
    onSelect={onSelect}
    onSearch={onSearch}
    label={label}
    formatter={formatter}
  />;
};

export const round = input => {
  return Math.round(input * 100) / 100;
};
