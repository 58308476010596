import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}superadmin`} component={asyncComponent(() => import('../containers/SuperAdmin/SuperAdmin'))}/>
      <Route path={`${match.url}superadmins_client/:id?`} component={asyncComponent(() => import('../containers/SuperAdmin/SuperAdminsClient'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('../containers/Dashboard/Dashboard'))}/>
      <Route path={`${match.url}invoices`} component={asyncComponent(() => import('../containers/Invoices/Invoices'))}/>
      <Route path={`${match.url}invoice/:id?`} component={asyncComponent(() => import('../containers/Invoices/Invoice'))}/>
      <Route path={`${match.url}customers`} component={asyncComponent(() => import('../containers/Customers/Customers'))}/>
      <Route path={`${match.url}customer/:id?`} component={asyncComponent(() => import('../containers/Customers/Customer'))}/>
      <Route path={`${match.url}items`} component={asyncComponent(() => import('../containers/Items/Items'))}/>
      <Route path={`${match.url}item/:id?`} component={asyncComponent(() => import('../containers/Items/Item'))}/>
      <Route path={`${match.url}payment_methods`} component={asyncComponent(() => import('../containers/Settings/PaymentMethods/PaymentMethods'))}/>
      <Route path={`${match.url}payment_method/:id?`} component={asyncComponent(() => import('../containers/Settings/PaymentMethods/PaymentMethod'))}/>
      <Route path={`${match.url}quotes`} component={asyncComponent(() => import('../containers/Quotes/Quotes'))}/>
      <Route path={`${match.url}quote/:id?`} component={asyncComponent(() => import('../containers/Quotes/Quote'))}/>
      <Route path={`${match.url}settings/:page?`} component={asyncComponent(() => import('../containers/Settings/Settings'))}/>
      <Route path={`${match.url}clients_import/:id?`} component={asyncComponent(() => import('../containers/Settings/ClientsImport/ClientsImport'))}/>
    </Switch>
  </div>
);

export default App;
