import React, {Component} from 'react';
import {Input, Modal} from 'antd/lib/index';
import PropTypes from "prop-types";
import * as actions from "./InvoicePdfActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {joditEditorReadOnly, serverDateFormat} from "../Common/FormDefaults";
import moment from "moment";
import JoditEditor from "jodit-react";
import 'jodit/build/jodit.min.css';
import 'jodit';

class InvoicePdfModal extends Component {


  save = () => {
    const {invoice, token} = this.props;
    invoice.submit_date = moment().format(serverDateFormat);
    invoice.line_items.map((item) => item.accepted ? item.accepted : item.accepted = false);
    this.props.actions.saveInvoiceByToken(invoice, token, this.props.cancel);
  };

  render() {
    const { invoice } = this.props;
    const {TextArea} = Input;
    console.log(invoice);
    return (
      <Modal
        title="Are you sure?"
        visible
        width="50%"
        style={{top: 130}}
        onCancel={this.props.cancel}
        onOk={() => this.save()}
      >
        <JoditEditor
          config={joditEditorReadOnly}
          value={invoice.client.terms_of_service}
        />
        <h2 className="gx-pdf-notification"><i className="icon icon-exclamation gx-mr-2"/>You cannot undo this action
        </h2>
      </Modal>
    );
  }
}

InvoicePdfModal.propTypes = {
  invoice: PropTypes.func.isRequired,
  token: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(InvoicePdfModal));

