import {Card,} from 'antd';
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class ResetEmailSent extends Component {

  render = () => {
    const email = this.props.match.params.email;
    return <div className="gx-main-content-wrapper">
      <Card className="gx-card" title="Reset password">
        Reset password email sent to {email}.
      </Card>
    </div>;
  };
}

ResetEmailSent.propTypes = {};

export default withRouter(ResetEmailSent);
