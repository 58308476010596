export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';

const initialState = {
  authenticated: false,
  configured: false,
  token: null,
  client: null,
  uid: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {...initialState, authenticated: false, token: null};
    case LOGIN:
      return {
        ...state,
        token: action.token,
        client: action.client,
        uid: action.uid,
        role: action.role,
        email: action.email,
        authenticated: true
      };
    default:
      return state;
  }
};
