import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import * as actions from "./TokenSignUpPageActions";
import {bindActionCreators} from "redux";

class TokenSignUp extends React.Component {
  componentDidMount() {
    const token = this.props.match.params.token;

    this.props.actions.tokenSignUp(token, () => {
      this.props.history.push('/invoices');
    }, e => {
      console.error(e);
    });
  }

  render() {
    return (
      <div></div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(TokenSignUp));
