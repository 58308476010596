import axios from 'axios';
import {LOGIN} from "../../appRedux/reducers/PersistedStateReducer";

export const signIn = (user, callback, errorCallback) => async (dispatch) => {
  const config = {
    url: '/auth/sign_in',
    method: 'POST',
    data: {...user},
    skipAlert: true
  };
  const result = await axios.request(config)
    .catch(e => {
      if (e.response && e.response.status === 401) {
        Promise.resolve(e);
        errorCallback(e.response.data.errors);
      }else{
        errorCallback('Connection error');
      }
    });
  if (result) {
    const user = result.data.data;
    dispatch({
      type: LOGIN,
      token: result.headers['access-token'],
      client: result.headers['client'],
      uid: result.headers['uid'],
      role: user.role,
      email: user.email
    });
    callback(user);
  }
};
