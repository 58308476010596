import {Button, Card, Form, Input,} from 'antd';
import React, {Component} from 'react';
import * as actions from "./NewPasswordFormActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {formItemLayout} from "../Common/FormDefaults";


class NewPasswordForm extends Component {

  state = {ready: false};

  save = (e) => {
    e.preventDefault();
    const token = this.props.match.params.token;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.props.actions.resetPassword(token, values.password,
        () => this.props.history.push('/password_reset'),
        error => this.setState({error}));
    });
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    this.props.actions.verifyToken(token, result => {
      this.setState({...result, ready: true});
    });
  }

  render = () => {
    const {getFieldDecorator} = this.props.form;
    const {ready, found} = this.state;
    return ready ? <div className="gx-main-content-wrapper">
      <Card className="gx-card" title="Reset password">
        {found ?
          <Form onSubmit={this.save} {...formItemLayout}>
            <Form.Item
              label="New Password"
            >
              {getFieldDecorator('password', {
                rules: [{required: true, message: 'Please input your new password!'}]
              })(
                <Input/>
              )}
            </Form.Item>
            <Form.Item
              label="Repeat new password"
            >
              {getFieldDecorator('password_repeat', {
                rules: [{required: true, message: 'Please repeat your new password!'}]
              })(
                <Input/>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Reset
              </Button>
              <Button type="ghost" onClick={this.props.cancel}>
                Cancel
              </Button>
            </Form.Item>
          </Form> : 'The link is invalid'}
      </Card>
    </div> : 'Loading...';
  };

}

NewPasswordForm.propTypes = {};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    actions,
    dispatch)
});

export default withRouter(connect(
  undefined,
  mapDispatchToProps
)(Form.create()(NewPasswordForm)));
