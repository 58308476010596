export const CONFIGURED = 'CONFIGURED';

const initialState = {
  configured: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIGURED:
      return {...initialState, configured: true};
    default:
      return state;
  }
};
