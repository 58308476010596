import {SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV} from "../../appRedux/reducers/Settings";

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export function toggleCollapsedSideNav(navCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}
