import axios from 'axios';

export const loadCustomFields = (id, client_id, referenced_model_name, callback) => async () => {
  const config = {
    url: '/custom_fields',
    params: {referenced_model_name, id, context_client_id: client_id }
  };
  const result = await axios.request(config);
  callback(result.data);
};
