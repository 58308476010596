import axios from "axios";

export const loadInvoice = (id, callback) => async () => {
  const config = {url: `/invoices/${id}`};
  const result = await axios.request(config);
  callback(result.data);
};


export const createDraft = (quotes, callback) => async () => {
  const config = {url: `/invoices/`, method: 'POST', data: {invoice: {type: 'draft', quotes}}};
  const result = await axios.request(config);
  callback(result.data);
};

export const convertToInvoice = (invoice, callback) => async () => {
  const invoiceToConvert = Object.assign({}, invoice);
  const line_items = invoice.line_items.filter(item => {
    if (item.accepted !== false) {
      delete item.id;
      return item;
    }
  });

  delete invoiceToConvert.id;
  delete invoiceToConvert.token;
  delete invoiceToConvert.current_status;
  delete invoiceToConvert.sent_count;
  delete invoiceToConvert.show_info_in_edit_mode;
  delete invoiceToConvert.show_customer_in_edit_mode;
  delete invoiceToConvert.has_custom_fields;
  delete invoiceToConvert.client;
  delete invoiceToConvert.customer;
  invoiceToConvert.line_items = line_items;
  invoiceToConvert.quotes = false;
  const convertConfig = {
    url: `/invoices/`,
    method: 'POST',
    data: {invoice: invoiceToConvert},
    message: 'Estimate converted'
  };
  const result = await axios.request(convertConfig);
  const archiveConfig = {
    url: `/invoices/${invoice.id}`,
    method: 'PUT',
    data: {invoice: {archived: true}},
  };
  await axios.request(archiveConfig);
  callback(result.data);
};

export const deleteInvoice = (id, callback) => async () => {
  const config = {
    url: `/invoices/${id}`,
    method: 'DELETE',
    message: 'Invoice deleted'
  };
  await axios.request(config);
  callback();
};

export const saveInvoice = (invoice, callback) => async () => {
  const config = {
    url: `/invoices/${invoice.id}`,
    method: 'PUT',
    data: {invoice},
    message: 'Saved'
  };
  const result = await axios.request(config);
  callback(result.data);
};

export const renderInvoice = (body, callback) => async () => {
  const config = {
    url: `/invoices/render_pdf`,
    responseType: 'blob',
    method: 'POST',
    data: {body}
  };
  const result = await axios.request(config);
  callback(result.data);
};

export const downloadPdf = (fileName) => (file) => {
  const blob = new Blob([file], {type: 'application/pdf'});
  const a = document.createElement('a');
  const blobURL = URL.createObjectURL(blob);
  a.download = fileName;
  a.href = blobURL;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const loadInvoiceByToken = (token, callback) => async () => {
  const config = {
    url: `/invoices/token?token=${token}`,
    anonymous: true
  };
  const result = await axios.request(config);
  callback(result.data);
};

export const saveInvoiceByToken = (invoice, token, callback) => async () => {
  const config = {
    url: `/invoices/save_by_token?token=${token}`,
    method: 'POST',
    data: {invoice},
    message: 'Saved'
  };
  const result = await axios.request(config);
  callback(result.data);
};


export default {
  loadInvoice, createDraft, deleteInvoice, renderInvoice, saveInvoice, downloadPdf,
  loadInvoiceByToken, saveInvoiceByToken, convertToInvoice
};
